<template>
  <div class="height-fullscreen columns is-gapless is-centered is-mobile pt-4">
    <div v-if="showLobby && adventureTeam && adventure" class="column is-12-mobile is-10-tablet is-8-desktop is-6-widescreen is-6-fullhd mb-4">
      <div class="a columns is-mobile pt-3 mr-3 is-vcentered">
        <div class="column has-text-centered p-0 pl-3">
          <div class="is-size-5 has-monserrat-font has-text-weight-semibold has-text-white">{{adventureTeam ? `${adventureTeam.teamName.toUpperCase()}'S ` : ''}}LOBBY</div>
        </div>
      </div>
      <div class="b">
        <div class="container">
          <div class="card stage-min-size overlapping-container">
            <div class="card-content pb-6 px-4 pt-1">
              <div class="container has-text-centered">
                <i class="fas fa-2x fa-grip-lines has-text-grey-light"></i>
              </div>
              <div class="container">
                <div class="container has-text-centered mb-3">
                  <img
                  class="mb-2"
                  style="border-radius: 10px;"
                  :src="adventureTeam.preview_image_url"
                  alt="Adventure Preview Image"
                  v-if="adventureTeam.uiMods.showAdventureIntroInLobby && adventureTeam.briefing && adventureTeam.preview_image_url"
                  />
                  <div class="is-size-4 has-text-weight-semibold has-baskerville-font has-text-brand-color">
                    {{ adventure.name }}
                  </div>
                 </div>
                <div
                v-if="adventureTeam.uiMods.showAdventureIntroInLobby && adventureTeam.briefing"
                class="notranslate"
                v-markdown>{{ adventureTeam.briefing }}
                </div>
              </div>
              <hr class="solid">
              <div v-if="!adventure.isActive && adventure.isActiveNotice" class="notification has-text-centered mb-4 py-2">
                {{ adventure.isActiveNotice }}
              </div>
              <div v-if="showJoinTeam || errorMessage" class="mb-4">
                <div class="container">
                  <div class="is-size-5 mb-2 has-text-weight-semibold">
                    Join {{ gameText.toLowerCase() }}
                  </div>
                  <FormInput :size="'medium'" :title="'Name'" :type="'text'" :value="name" v-on:update="name = $event"/>
                </div>
                <div v-if="true" class="container has-text-centered">
                  <p class="help is-danger">{{ errorMessage }}</p>
                </div>
                <div
                @click="joinTeam()"
                class="button is-primary-colors has-text-weight-semibold is-fullwidth mt-2">
                  Join team
                </div>
                <hr class="solid"/>
              </div>
              <div class="container mb-6 pb-4">
                <div class="is-size-5 mb-2 has-text-weight-semibold">
                  Players in {{ adventureTeam.teamName }}
                </div>
                <div v-for="(member, index) in adventureTeam.teamMembers" class="is-size-6" :key="index">
                  <i v-if="member.name !== userName" class="fas fa-user fa-fw mr-2"></i>
                  <i v-if="member.name === userName" class="fas fa-user-check fa-fw mr-2"></i>
                  {{ member.name }}
                </div>
                <ChangeTeamNameButton
                  :teamCode="adventureTeam.teamCode"
                  :isButton="true"
                />
              </div>
              <hr class="solid">
              <div v-if="!adventureTeam.maxPlayersPerTeam || (adventureTeam.maxPlayersPerTeam && adventureTeam.maxPlayersPerTeam > 1)">
                <div class="container">
                  <div class="is-size-5 has-text-weight-semibold">
                    Share team link with others
                  </div>
                  <div @click="copyUrlToclipboard()" class="is-size-6 pointer">
                    <u>{{ shareLink }}</u>
                  </div>
                </div>
                <div class="container has-text-centered">
                  <div
                  @click="copyUrlToclipboard()"
                  class="button is-primary-colors has-text-weight-semibold overflow-button mt-4 mb-3"
                  v-bind:class="{'is-light': urlCopied}"
                  >
                    <div>
                      {{ urlCopied ? "Copied! Simply paste it to your friends." : "Copy team link" }}
                    </div>
                  </div>
                  <div class="is-size-6 has-text-grey mb-3 px-4">
                    or show this QR code
                  </div>
                  <qrcode-vue :value="shareLink" :size="150" level="H" />
                </div>
              </div>
              <hr class="solid">
              <div v-if="!showJoinTeam && !errorMessage">
                <div class="container">
                  <label v-if="adventureTeam.allowRemoveTimeLimits" class="checkbox mb-2">
                    <input type="checkbox" v-model="removeTimeLimits">
                    Remove time limits (for teams with mobility-impeded players)
                  </label>
                  <div class="container has-text-centered">
                    <div
                    v-if="adventure.isActive"
                    @click="startGame()"
                    class="white-space-normal button is-primary-colors has-text-weight-semibold is-large mt-2 mb-2"
                    :disabled="loading"
                    v-bind:class="{'is-light is-loading': loading}"
                    >
                      {{ t(`Click here to start`) }}
                    </div>
                    <div
                    v-else
                    class="button is-light has-text-weight-semibold is-large mt-2 mb-2"
                    :disabled="true"
                    >
                      {{ gameText }} Inactive
                    </div>
                    <article v-if="errorMessage" class="message is-danger mt-2 mb-2">
                      <div class="message-body py-2">
                      {{ errorMessage }}
                      </div>
                    </article>
                    <div v-if="isAdmin && (!adventureTeam.maxPlayersPerTeam || (adventureTeam.maxPlayersPerTeam && adventureTeam.maxPlayersPerTeam > 1))" class="is-size-6 has-text-grey-light has-text-centered">
                      Players can join even after the {{ gameText.toLowerCase() }} has started.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <OverlapLoadingScreen v-else/>
    <div v-if="showScrollDownButton" class="button has-text-weight-semibold is-small is-success scroll-button-modifier is-rounded">
      <i class="fas fa-angle-double-down mr-2"></i>
      {{ t('Scroll down to start') }}
      <i class="fas fa-angle-double-down ml-2"></i>
    </div>
  </div>
</template>

<script>
import OverlapLoadingScreen from '@/components/OverlapLoadingScreen.vue';
import FormInput from '@/components/forms/FormInput'
import firebaseApp from '@/firebase/init'
import QrcodeVue from 'qrcode.vue'
import { getFirestore, doc, getDoc, onSnapshot } from 'firebase/firestore'
import ChangeTeamNameButton from '@/components/ChangeTeamNameButton.vue';


const db = getFirestore(firebaseApp)

export default {
  name: 'Lobby',
  components: {
    FormInput,
    OverlapLoadingScreen,
    QrcodeVue
  },
  data() {
    return {
      showLobby: false,
      loading: false,
      errorMessage: null,
      name: '',
      removeTimeLimits: false,
      canCopy: false,
      urlCopied: false,
      teamCode: this.$route.params.teamCode,
      adventureTeam: null,
      adventure: null,
      sessionId: this.$route.params.sessionId,
      showScrollDownButton: true
    }
  },
  methods: {
    getAdventureByAdventureId(){
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      masterFunction({
        methodName: 'get-adventure-by-adventure-id',
        adventureId: this.adventureTeam.adventureId,
        sessionId: this.adventureTeam.sessionId
      }).then(result => {
        if(!result.data){
          // console.log(result)
        } else {
          if(result.data){
            this.adventure = result.data
            this.setCustomButtonColour(
              result.data.uiMods.customButtonBackgroundColour,
              result.data.uiMods.customButtonFontColour
            )
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    joinTeam() {
      this.errorMessage = null
      this.loading = true
      if (this.name && !this.name.includes('TESTING')){
        this.$mixpanel.track('player_joins', {
          adventure_id: this.adventureTeam.adventureId,
          adventure_name: this.adventureTeam.adventureName,
          session_id: this.adventureTeam.sessionId
        })
      }
      this.$store.commit('updateUserName', this.name)
      this.$store.commit('updateTeamCode', this.teamCode)
      this.$store.commit('updateAdventureName', this.adventureTeam.adventureName)
      this.$store.commit('updateAdventureId', this.adventureTeam.adventureId)
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      masterFunction({
        methodName: 'join-team',
        name: this.name,
        teamCode: this.teamCode,
        sessionId: this.sessionId
      }).then(() => {
        this.loading = false
      }).catch(err => {
        this.errorMessage = err.message
        this.loading = false
        console.log(err)
      })
    },
    startGame() {
      this.loading = true
      if(this.userName && !this.userName.includes('TESTING')){
        this.$mixpanel.track('game_start', {
          adventure_id: this.adventureTeam.adventureId,
          adventure_name: this.adventureTeam.adventureName,
          session_id: this.adventureTeam.sessionId
        })
        this.$mixpanel.track('player_joins', {
          adventure_id: this.adventureTeam.adventureId,
          adventure_name: this.adventureTeam.adventureName,
          session_id: this.adventureTeam.sessionId
        })
      }
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      masterFunction({
        methodName: 'start-adventure',
        teamCode: this.teamCode,
        removeTimeLimits: this.removeTimeLimits,
        userLat: null,
        userLon: null,
        timezone: 8,
      }).then(() => {
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.errorMessage = err.message
      })
    },
    async copyUrlToclipboard() {
      this.urlCopied = true
      await navigator.clipboard.writeText(this.shareLink);
      setTimeout(() => { this.urlCopied= false }, 1500)
    },
    refreshLocation() {
      this.loading = true
      setTimeout(() => { this.loading = false }, 1500)
      this.$store.dispatch('getUserLocationAction', this.t)
    },
    setCustomButtonColour(backgroundColour, textColour) {
      setTimeout(() => {
        document.getElementsByClassName('button').forEach(div => {
          if(backgroundColour && textColour){
            div.setAttribute( 'style', `background-color: ${backgroundColour} !important; color: ${textColour} !important` )
          } else if (backgroundColour){
            div.setAttribute( 'style', `background-color: ${backgroundColour} !important` )
          } else if (textColour){
            div.setAttribute( 'style', `color: ${textColour} !important` )
          }
          // div.style.fontFamily = "'userCustom'"
        })
      }, 1500)
    },
    subscribeToAdventureTeam(teamCode) {
      const docRef = doc(db, 'adventureTeams', teamCode)
      const sendTelemetryLog = async (error) => {
        const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
        try {
          const browserInfo = {
            userAgent: navigator.userAgent,
            platform: navigator.platform,
            language: navigator.language,
            screenResolution: `${window.screen.width}x${window.screen.height}`,
            viewportSize: `${window.innerWidth}x${window.innerHeight}`
          }
          await masterFunction({
            methodName: 'add-telemetry-log',
            data: {
              error: error.message || error,
              teamCode: teamCode,
              userName: this.$store.state.userName,
              sessionId: this.$route.params.sessionId,
              adventureTeam: this.adventureTeam,
              adventureId: this.adventureId,
              adventure: this.adventure,
              browserInfo: browserInfo
            },
            identifier: teamCode
          })
        } catch (telemetryErr) {
          console.error('Error sending telemetry log:', telemetryErr)
        }
      }

      const trySubscribe = async (attempts) => {
        if (attempts <= 0) {
          await sendTelemetryLog('Invalid game link')
          alert('Invalid game link.')
          this.$router.push({ name: 'Start' })
          return
        }

        onSnapshot(docRef, async (docSnap) => {
          try {
            if (docSnap.exists()) {
              this.adventureTeam = docSnap.data()
              document.title = `${this.adventureTeam.adventureName}`
            } else {
              setTimeout(() => trySubscribe(attempts - 1), 5000)
            }
          } catch (err) {
            console.error('Error processing document snapshot:', err)
            await sendTelemetryLog(err)
          }
        }, async (error) => {
          console.error('Error subscribing to adventure team:', error)
          await sendTelemetryLog(error)
        })
      }

      trySubscribe(3)
    },
    processDirectJoin () {
      this.name = this.$route.query.teamName
      this.startGame()
      this.joinTeam()
    }
  },
  computed: {
    isTeam() {
      return this.$store.state.isTeam
    },
    userName() {
      return this.$store.state.userName
    },
    teamCodeLocalStorage() {
      return this.$store.state.teamCode
    },
    showJoinTeam() {
      return (
        (
          !this.userName ||
          this.teamCodeLocalStorage !== this.teamCode ||
          (
            this.teamCodeLocalStorage === this.teamCode &&
            !this.adventureTeam.teamMembers.some(member => member.name === this.$store.state.userName)
          )
        )
      )
    },
    isAdmin(){
      return (this.$store.state.userName && this.teamCodeLocalStorage === this.teamCode && this.adventureTeam && this.adventureTeam.teamMembers.some(member => {
        return member.name === this.$store.state.userName && member.isAdmin
      }))
    },
    shareLink(){
      if(this.adventureTeam){
        return `${window.location.origin}/lobby/${this.teamCode}`
      }
      return ''
    },
    gameText() {
      if (this.adventure) {
        return this.adventure.uiMods.game ? this.adventure.uiMods.game : 'Game'
      }
      return 'Game'
    }
  },
  watch: {
    teamCode: {
      immediate: true,
      handler(teamCode) {
        if(teamCode){
          this.subscribeToAdventureTeam(teamCode)
        }
      },
    },
    adventureTeam(adventureTeam) {
      if(adventureTeam && !this.adventure){
        this.$store.commit('updateGameCode', this.adventureTeam.sessionId)

        this.getAdventureByAdventureId()
        if(adventureTeam){

          if (
            this.adventureTeam.teamMembers && this.adventureTeam.teamMembers.length === 1
            && adventureTeam.maxPlayersPerTeam === 1
          ) {
            if (
              this.$store.state.userName &&
              this.adventureTeam.teamMembers.length > 0 &&
              this.$store.state.userName === this.adventureTeam.teamMembers[0].name
            ) {
              this.$store.commit('updateUserName', this.adventureTeam.teamMembers[0].name)
            }
            this.$store.commit('updateTeamCode', this.teamCode)
          }

          if (
            // (this.$store.state.userName === 'TESTING') ||
            (
              (
                (adventureTeam.maxPlayersPerTeam && adventureTeam.maxPlayersPerTeam <= 1) ||
                this.$store.state.isTeam === false
              )
              && this.adventureTeam.teamMembers && this.adventureTeam.teamMembers.length > 0
              && this.adventureTeam.teamMembers.some(member => member.name === this.$store.state.userName)
            )
            // ||
            // // NOTE: removed because it made everyone skip lobby, but not sure why this was originally added here - Mo, 24 June 2024
            // (
            //   this.adventureTeam.teamMembers && this.adventureTeam.teamMembers.length == 1
            //   && this.adventureTeam.teamMembers.some(member => member.name === this.$store.state.userName)
            //   && this.$store.state.teamCode === this.teamCode
            // )
          ){
            this.$store.commit('updateIsTeam', null)
            this.startGame()
          } else {
            this.showLobby = true
          }
        }

        if (
          this.$route.query.directJoin === 'true' &&
          this.$route.query.teamName
        ) {
          this.processDirectJoin()
        }
      }
      if(
        adventureTeam &&
        adventureTeam.startedAt &&
        this.teamCodeLocalStorage === this.teamCode &&
        this.adventureTeam.teamMembers.some(member => member.name === this.$store.state.userName)
      ){
        if (this.$route.query.directJoin === 'true') {
          this.$router.push({ name: 'Stage', query: { directJoin: 'true' } })
        } else {
          this.$router.push({ name: 'Stage' })
        }
      }

      if(adventureTeam && adventureTeam.uiMods.defaultBackgroundColour){
        const bgElement = document.querySelector('.has-background-brand-color')
        const htmlElement = document.querySelector('html')
        if(bgElement) bgElement.classList.remove('has-background-brand-color')
        if(htmlElement) htmlElement.style.backgroundColor = adventureTeam.uiMods.defaultBackgroundColour
      } else {
        document.querySelector('html').style.backgroundColor = '#064343'
      }
    }
  },
  created() {
    this.canCopy = !!navigator.clipboard
  },
  mounted() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight*0.8) {
        this.showScrollDownButton = false
      }
    }

    this.$store.commit('getUserLocationMutation', {
      userAllowsLocationDetection: null,
      lat: null,
      lon: null
    })
    this.$store.commit('updateCurrentStage', null)
    this.$store.commit('updateTaskCategoryFilter', null)

    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.sessionId)) {
      this.$store.commit('updateUserName', this.sessionId)
      this.$store.commit('updateTeamCode', this.teamCode)
      this.sessionId = null
    }
  }
}
</script>

<style scoped>
.a{
  position: sticky;
  top: 0;
}

hr.solid {
  border-top: 1px solid rgb #E2E2E2;
}

.scroll-button-modifier {
    position: fixed;
    left: 50%;
    bottom: 20px;
    z-index: 2;
    animation: scrollDown 0.7s infinite ease-in-out;
}

@keyframes scrollDown {
0% {
    transform: translateY(0px) translate(-50%, 0);
}
50% {
    transform: translateY(10px) translate(-50%, 0);
}
100% {
    transform: translateY(0px) translate(-50%, 0);
}
}
</style>
